import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchAll } from '../../services/vadr-scores';
import { VadrScores } from '../../types/vadr-scores';

const KEY = 'vadr-scores';

export function useVadrScores({
  orgId,
  pid,
  vectorIds,
  options,
}: {
  orgId: string;
  pid: string;
  vectorIds?: string[];
  options?: Omit<UseQueryOptions<VadrScores[]>, 'queryKey' | 'queryFn'>;
}) {
  return useQuery<VadrScores[]>({
    queryKey: [KEY, orgId, pid, vectorIds],
    queryFn: () => fetchAll(orgId, pid, vectorIds),
    enabled: !!orgId && !!pid,
    // temporary workaround to handle both array and single data
    // while we transition to the new api
    select: (data) => (Array.isArray(data) ? data : [data]),
    ...options,
  });
}
