import { VectorType } from './assays';

interface CreateFlowRunParams {
  orgId: string;
  pid: string;
  analysisName: string;
}

export interface CreateCaasFromVectorIdParams extends CreateFlowRunParams {
  vectorId: string;
}

export interface CreateCaasFlowRunParams extends CreateFlowRunParams {
  vectorFile: File;
  indication?: string;
  vectorName: string;
  vectorType: VectorType;
  gene?: string;
}

export type CreateCaasFromPathParams = Omit<
  CreateCaasFlowRunParams,
  'vectorFile' | 'vectorFiles'
> & {
  vaultFile: string;
};

export function isCaasFromVectorIdParams(
  params:
    | CreateFlowRunParams
    | CreateCaasFromVectorIdParams
    | CreateCaasFromPathParams,
): params is CreateCaasFromVectorIdParams {
  return (params as CreateCaasFromVectorIdParams).vectorId !== undefined;
}

export function isCaasFromVectorPath(
  params:
    | CreateFlowRunParams
    | CreateCaasFromVectorIdParams
    | CreateCaasFromPathParams,
): params is CreateCaasFromPathParams {
  return (params as CreateCaasFromPathParams).vaultFile !== undefined;
}

export type FlowRunType = 'caas';

export type FlowRunStatus = 'CREATED' | 'RUNNING' | 'COMPLETED' | 'ERROR';

export interface FlowRun {
  id: string;
  name: string;
  type: FlowRunType;
  status: FlowRunStatus;
  metadata: object;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  projectId: string;
  completedAt?: string;
}

interface FlowRunMetadataCaas {
  vectorId: string;
  vectorGCPath?: string;
  inputDataPath?: string;
  vectorCPGPath?: string;
  vectorORFPath?: string;
  vectorJSONPath?: string;
  vectorFastaPath?: string;
  vectorSourcePath?: string;
  vectorGenbankPath?: string;
  packagingFastaPath?: string;
  vectorSeqparsePath?: string;
  vectorSeqLengthPath?: string;
  reportPath?: string;
  aavRunId?: string;
  laavaRunId?: string;
}

export interface FlowRunCaas extends FlowRun {
  type: 'caas';
  metadata: FlowRunMetadataCaas;
}
