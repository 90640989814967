import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import {
  createDatacoreVectorFisaRuns,
  createISAVector,
  fetchAll,
  fetchOne,
  fetchVectorFisaRunStatus,
  updateISAVector,
  updateVectorEntityMetadata,
} from '../../services/isa-vectors';
import {
  CreateISAVectorParams,
  DATACORE_VECTOR_TYPE,
  FisaStatusRes,
  ISAVector,
  UpdateISAVectorProfileParams,
  UpdateISAVectorParams,
  ISACreateResponse,
} from '../../types';
import useInvalidation from '../useInvalidation';

const KEY = 'isa-vectors';

export function useISAVectors({
  orgId,
  pid,
  options,
}: {
  orgId: string;
  pid: string;
  options?: Omit<UseQueryOptions<ISAVector[]>, 'queryKey' | 'queryFn'>;
}) {
  return useQuery<ISAVector[]>({
    queryKey: [KEY, orgId, pid],
    queryFn: () => fetchAll(orgId, pid),
    enabled: !!orgId && !!pid,
    ...options,
  });
}

export function useISAVector(
  params: Parameters<typeof fetchOne>[0],
  options?: Omit<UseQueryOptions<ISAVector>, 'queryKey' | 'queryFn'>,
) {
  return useQuery<ISAVector>({
    queryKey: [KEY, params],
    queryFn: () => fetchOne(params),
    ...{
      enabled: !!params.id,
      ...options,
    },
  });
}

export function useCreateISAVector(
  options?: UseMutationOptions<
    AxiosResponse<ISACreateResponse>,
    AxiosError<{ message: string }>,
    CreateISAVectorParams
  >,
) {
  const invalidate = useInvalidation(KEY);
  const invalidateVectors = useInvalidation(DATACORE_VECTOR_TYPE);

  return useMutation<
    AxiosResponse<ISACreateResponse>,
    AxiosError<{ message: string }>,
    CreateISAVectorParams
  >({
    mutationFn: (data: CreateISAVectorParams) => createISAVector(data),
    ...{
      onSuccess: () => {
        invalidateVectors();
        return invalidate();
      },
      ...options,
    },
  });
}

export function useUpdateISAVector(
  options?: UseMutationOptions<
    AxiosResponse<{ id: string }>,
    AxiosError<{ message: string }>,
    UpdateISAVectorParams
  >,
) {
  const invalidate = useInvalidation(KEY);
  const invalidateVectors = useInvalidation(DATACORE_VECTOR_TYPE);

  return useMutation<
    AxiosResponse<{ id: string }>,
    AxiosError<{ message: string }>,
    UpdateISAVectorParams
  >({
    mutationFn: (data: UpdateISAVectorParams) => updateISAVector(data),
    ...{
      ...options,
      onSuccess: (...params) => {
        options?.onSuccess?.(...params);
        invalidateVectors();
        return invalidate();
      },
    },
  });
}

export function useCreateDatacoreVectorFisaRuns(
  options?: UseMutationOptions<
    AxiosResponse<{ id: string }>,
    AxiosError<{ message: string }>,
    { oid: string; pid: string; datacoreVectorIds: string[] }
  >,
) {
  const invalidate = useInvalidation(KEY);
  const invalidateVectors = useInvalidation(DATACORE_VECTOR_TYPE);

  return useMutation<
    AxiosResponse<{ id: string }>,
    AxiosError<{ message: string }>,
    { oid: string; pid: string; datacoreVectorIds: string[] }
  >({
    mutationFn: ({
      oid,
      pid,
      datacoreVectorIds,
    }: {
      oid: string;
      pid: string;
      datacoreVectorIds: string[];
    }) => createDatacoreVectorFisaRuns(oid, pid, datacoreVectorIds),
    ...{
      ...options,
      onSuccess: (...params) => {
        options?.onSuccess?.(...params);
        invalidateVectors();
        return invalidate();
      },
    },
  });
}

export function useUpdateVectorProfile(
  options?: UseMutationOptions<
    AxiosResponse<{ id: string }>,
    AxiosError<{ message: string }>,
    UpdateISAVectorProfileParams
  >,
) {
  const invalidate = useInvalidation(DATACORE_VECTOR_TYPE);

  return useMutation<
    AxiosResponse<{ id: string }>,
    AxiosError<{ message: string }>,
    UpdateISAVectorProfileParams
  >({
    mutationFn: (data: UpdateISAVectorProfileParams) => {
      return updateVectorEntityMetadata(
        data.orgId,
        data.pid,
        data.datacoreId,
        data.name,
        data.typeSchemaVersionName,
        data.metadata,
      );
    },
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}

export function useVectorFisaRunStatus({
  orgId,
  pid,
  vectorId,
  options,
}: {
  orgId: string;
  pid: string;
  vectorId: string;
  options?: Omit<UseQueryOptions<FisaStatusRes>, 'queryKey' | 'queryFn'>;
}) {
  return useQuery<FisaStatusRes>({
    queryKey: ['vector-fisa-run-status', orgId, pid, vectorId],
    queryFn: () => fetchVectorFisaRunStatus(orgId, pid, vectorId),
    enabled: !!orgId && !!pid && !!vectorId,
    ...options,
  });
}
