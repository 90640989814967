import { VadrScores } from '@formbio/api/src/types/vadr-scores';

export const vadrScores: VadrScores[] = [
  {
    name: 'CaaS Configuration',
    heatmapConfig: {
      defaultSortScore: {
        scoreAttribute: 'overallCompositeScore',
        displayName: 'Overall',
        isComposite: true,
      },
      columnScores: [
        {
          scoreAttribute: 'overallCompositeScore',
          displayName: 'Overall',
          isComposite: true,
        },
        {
          scoreAttribute: 'partialsScore',
          valueAttribute: 'partials',
          displayName: 'Partials',
        },
        {
          scoreAttribute: 'mismatchesScore',
          valueAttribute: 'mismatches',
          displayName: 'Mismatches',
        },
        {
          scoreAttribute: 'backboneScore',
          valueAttribute: 'backbone',
          displayName: 'Backbone',
        },
        {
          scoreAttribute: 'residualHostCellDnaScore',
          valueAttribute: 'residualHostCellDna',
          displayName: 'Residual Host-Cell DNA',
        },
        {
          scoreAttribute: 'residualPlasmidDnaScore',
          valueAttribute: 'residualPlasmidDna',
          displayName: 'Residual Plasmid DNA',
        },
        {
          scoreAttribute: 'cpgIslandsScore',
          valueAttribute: 'cpgIslands',
          displayName: 'CPG Islands',
        },
        {
          scoreAttribute: 'manufacturabilityScore',
          valueAttribute: 'manufacturability',
          displayName: 'Manufacturability',
        },
        {
          scoreAttribute: 'partialGenomesScore',
          valueAttribute: 'partialGenomes',
          displayName: 'Partial Genomes',
        },
      ],
    },
    vectorValues: [
      {
        entityId: 'b3cb114e-c6df-42ad-b837-4a1ed7f3e683',
        entityName: 'BlueScribe',
        overallCompositeScore: 0.8987499999999999,
        partials: 12,
        mismatches: 5,
        backbone: 8,
        residualHostCellDna: 2,
        residualPlasmidDna: 14,
        cpgIslands: 23,
        manufacturability: 15,
        partialGenomes: 2,
        partialsScore: 0.88,
        mismatchesScore: 0.95,
        backboneScore: 0.92,
        residualHostCellDnaScore: 0.98,
        residualPlasmidDnaScore: 0.86,
        cpgIslandsScore: 0.77,
        manufacturabilityScore: 0.85,
        partialGenomesScore: 0.98,
      },
      {
        entityId: '73ca90b8-10e5-4923-880b-fe2c3242b5b8',
        entityName: 'KL Test Vector',
        overallCompositeScore: 0.24875,
        partials: 70,
        mismatches: 80,
        backbone: 65,
        residualHostCellDna: 72,
        residualPlasmidDna: 90,
        cpgIslands: 68,
        manufacturability: 84,
        partialGenomes: 72,
        partialsScore: 0.30000000000000004,
        mismatchesScore: 0.19999999999999996,
        backboneScore: 0.35,
        residualHostCellDnaScore: 0.28,
        residualPlasmidDnaScore: 0.09999999999999998,
        cpgIslandsScore: 0.31999999999999995,
        manufacturabilityScore: 0.16000000000000003,
        partialGenomesScore: 0.28,
      },
      {
        entityId: 'ec837ca0-0c65-4478-a543-5ef772e494ea',
        entityName: 'KL BlueScribe Test 2',
        overallCompositeScore: 0.125,
        partials: 0,
        residualPlasmidDna: 100,
        partialsScore: 1,
        residualPlasmidDnaScore: 0,
      },
    ],
  },
  {
    name: 'Alternative Configuration',
    heatmapConfig: {
      defaultSortScore: {
        scoreAttribute: 'overallCompositeScore',
        displayName: 'Overall',
        isComposite: true,
      },
      columnScores: [
        {
          scoreAttribute: 'overallCompositeScore',
          displayName: 'Overall',
          isComposite: true,
        },
        {
          scoreAttribute: 'partialsScore',
          valueAttribute: 'partials',
          displayName: 'Partials',
        },
        {
          scoreAttribute: 'mismatchesScore',
          valueAttribute: 'mismatches',
          displayName: 'Mismatches',
        },
        {
          scoreAttribute: 'backboneScore',
          valueAttribute: 'backbone',
          displayName: 'Backbone',
        },
        {
          scoreAttribute: 'residualHostCellDnaScore',
          valueAttribute: 'residualHostCellDna',
          displayName: 'Residual Host-Cell DNA',
        },
        {
          scoreAttribute: 'residualPlasmidDnaScore',
          valueAttribute: 'residualPlasmidDna',
          displayName: 'Residual Plasmid DNA',
        },
      ],
    },
    vectorValues: [
      {
        entityId: 'b3cb114e-c6df-42ad-b837-4a1ed7f3e683',
        entityName: 'BlueScribe',
        overallCompositeScore: 0.8987499999999999,
        partials: 12,
        mismatches: 5,
        backbone: 8,
        residualHostCellDna: 2,
        residualPlasmidDna: 14,
        cpgIslands: 23,
        manufacturability: 15,
        partialGenomes: 2,
        partialsScore: 0.88,
        mismatchesScore: 0.95,
        backboneScore: 0.92,
        residualHostCellDnaScore: 0.98,
        residualPlasmidDnaScore: 0.86,
        cpgIslandsScore: 0.77,
        manufacturabilityScore: 0.85,
        partialGenomesScore: 0.98,
      },
      {
        entityId: '73ca90b8-10e5-4923-880b-fe2c3242b5b8',
        entityName: 'KL Test Vector',
        overallCompositeScore: 0.24875,
        partials: 70,
        mismatches: 80,
        backbone: 65,
        residualHostCellDna: 72,
        residualPlasmidDna: 90,
        cpgIslands: 68,
        manufacturability: 84,
        partialGenomes: 72,
        partialsScore: 0.30000000000000004,
        mismatchesScore: 0.19999999999999996,
        backboneScore: 0.35,
        residualHostCellDnaScore: 0.28,
        residualPlasmidDnaScore: 0.09999999999999998,
        cpgIslandsScore: 0.31999999999999995,
        manufacturabilityScore: 0.16000000000000003,
        partialGenomesScore: 0.28,
      },
      {
        entityId: 'ec837ca0-0c65-4478-a543-5ef772e494ea',
        entityName: 'KL BlueScribe Test 2',
        overallCompositeScore: 0.125,
        partials: 0,
        residualPlasmidDna: 100,
        partialsScore: 1,
        residualPlasmidDnaScore: 0,
      },
    ],
  },
];
